export default JSON.parse(
  `[{
  "id": 94926,
  "note": "<p>Et quia eum nobis ut hic autem enim. Qui ea a qui accusantium </p>",
  "createTime": "2017-02-10T07:42:44.828Z",
  "color": 0
}, {
  "id": 51842,
  "note": "<p>Possimus exercitationem qui ea quo repudiandae. </p>",
  "createTime": "2017-04-08T03:17:31.999Z",
  "color": 0
}, {
  "id": 28180,
  "note": "<p>Tempore est velit non eaque. Exercitationem fugiat rerum magni.</p>",
  "createTime": "2017-01-21T05:43:16.889Z",
  "color": 3
}, {
  "id": 29619,
  "note": "<p>Officiis suscipit perferendis blanditiis aut aliquid quod et..</p>",
  "createTime": "2017-01-08T23:36:05.692Z",
  "color": 4
}, {
  "id": 19600,
  "note": "<p>Dolor quas est quae. Id temporibus sed. Illum quo amet est fuga laborum facilis. Aut nisi  quo.</p>",
  "createTime": "2017-05-11T22:30:14.915Z",
  "color": 4
}]`
);
