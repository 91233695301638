export default {
  apiUrl: 'http://yoursite.com/api/',
};

const siteConfig = {
  siteName: 'MetaAdmin',
  siteIcon: 'ion-flash',
  footerText: 'MetaAdmin ©2018 Created by RedQ, Inc',
  enableAnimatedRoute: false,
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
  showBreadCrumb: true,
  fixedNavbar: true,
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: 'BPYMKGJ92J',
  apiKey: '6562758d9a6bb3c858895c2c4d81b28c',
};
const Auth0Config = {
  domain: 'redq.auth0.com',
  clientID: 'C4au5pAkAstmL5ZQHoaVzvZRrdsHCpHf',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'http://localhost:3000/auth0loginCallback',
    },
    languageDictionary: {
      title: 'Metaadmin',
      emailInputPlaceholder: 'demo@gmail.com',
      passwordInputPlaceholder: 'demodemo',
    },
    icon: '',
    theme: {
      labeledSubmitButton: true,
      logo: 'https://s3.amazonaws.com/redqteam.com/logo/mate-admin.png',
      primaryColor: '#E14615',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000',
          icon: undefined,
        },
      },
    },
  },
};
const firebaseConfig = {
  apiKey: 'AIzaSyCEe-wWWliPXZjK0rivyhhVelJ81PPcNGI',
  authDomain: 'mateadmin-fbd4c.firebaseapp.com',
  databaseURL: 'https://mateadmin-fbd4c.firebaseio.com',
  projectId: 'mateadmin-fbd4c',
  storageBucket: 'mateadmin-fbd4c.appspot.com',
  messagingSenderId: '807402622228',
};
const googleConfig = {
  apiKey: 'AIzaSyD2ZQycUkoc6UGXZGTSrH1hgbIF4nlx1bE',
};
const mapboxConfig = {
  tileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  maxZoom: 18,
  defaultZoom: 11,
  center: [40.706877, -74.011265],
};
const youtubeSearchApi = 'AIzaSyDjq7LZKhLGIdvFpqMa70uglprIPcKQ3a4';

const instagramConfig = {
  instagramUserInfoApiUrl:
    'https://api.instagram.com/v1/users/self/?access_token=',
  instagramUserMediaApiUrl:
    'https://api.instagram.com/v1/users/self/media/recent/?access_token=',
  accessToken: '6885843072.1677ed0.bc2d694ea3cc45e0829aaac6afbb99c2',
};

export {
  siteConfig,
  language,
  themeConfig,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi,
  instagramConfig,
};
